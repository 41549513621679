import { camelizeKeys } from 'humps'

import { addFlashMessage } from '~/actions/flash-message'
import { i18n } from '~/i18n'
import getCustomerAPI from '~/utils/api/get-customer-api'
import HTTPError from '~/utils/http-error'

import { reportGroupedError } from '../utils/logger'

import type { Waiver } from '~/types'

export const GET_WAIVERS_REQUEST = 'waivers/GET_WAIVERS_REQUEST'
export const GET_WAIVERS_SUCCESS = 'waivers/GET_WAIVERS_SUCCESS'
export const GET_WAIVERS_FAILURE = 'waivers/GET_WAIVERS_FAILURE'

export type WaiversActions =
  | {
      type: 'waivers/GET_WAIVERS_REQUEST'
    }
  | {
      type: 'waivers/GET_WAIVERS_SUCCESS'
      waivers: Waiver[]
    }
  | {
      type: 'waivers/GET_WAIVERS_FAILURE'
    }

export const loadWaivers =
  ({
    reload = false,
  }: {
    reload?: boolean
  } = {}) =>
  async (
    dispatch: (...args: Array<any>) => any,
    getState: (...args: Array<any>) => any,
  ) => {
    const {
      waivers: { loaded },
    } = getState()

    if (loaded && !reload) return

    const api = getCustomerAPI()
    dispatch({
      type: GET_WAIVERS_REQUEST,
    })

    try {
      const resp = await api.getWaivers()

      if (resp.ok) {
        const { data } = await resp.json()
        dispatch({
          type: GET_WAIVERS_SUCCESS,
          waivers: camelizeKeys(data),
        })
      } else {
        throw new HTTPError('Unable to fetch waivers', resp, '')
      }
    } catch (error) {
      dispatch({
        type: GET_WAIVERS_FAILURE,
      })
      dispatch(
        addFlashMessage(
          'error',
          i18n.t('components.flash_messages.get_waivers_error'),
          {
            autoHide: true,
          },
        ),
      )
      reportGroupedError('loadWaivers', error)
    }
  }
