import { addFlashMessage } from '~/actions/flash-message'
import { i18n } from '~/i18n'
import HTTPError from '~/utils/http-error'

import { loadGroup } from './group'
import { postInvite } from '../utils/api/invite'
import { reportGroupedError } from '../utils/logger'

export const INVITE_PARTICIPANT_STARTED = 'invite-participant/STARTED'
export const INVITE_PARTICIPANT_COMPLETED = 'invite-participant/COMPLETED'
export const INVITE_PARTICIPANT_FAILED = 'invite-participant/FAILED'
export const OPEN_MODAL = 'invite-participant/OPEN_MODAL'
export const CLOSE_MODAL = 'invite-participant/CLOSE_MODAL'
export const GO_TO_STEP = 'invite-participant/GO_TO_STEP'
export const RESET_ERRORS = 'invite-participant/RESET_ERRORS'

type OpenModal = {
  type: 'invite-participant/OPEN_MODAL'
}

type CloseModal = {
  type: 'invite-participant/CLOSE_MODAL'
}

type Started = {
  type: 'invite-participant/STARTED'
}

type Completed = {
  type: 'invite-participant/COMPLETED'
}

type Failed = {
  type: 'invite-participant/FAILED'
  errorCode: InviteErrorCode
}

type GoToStep = {
  type: 'invite-participant/GO_TO_STEP'
  step: number
}

type ResetErrors = {
  type: 'invite-participant/RESET_ERRORS'
}

type InviteErrorCode = 'ALREADY_REGISTERED' | 'UNKNOWN_ERROR'

export type InviteActions =
  | OpenModal
  | CloseModal
  | Started
  | Completed
  | Failed
  | GoToStep
  | ResetErrors

export function openInviteParticipantModal() {
  return {
    type: OPEN_MODAL,
  }
}

export function closeInviteParticipantModal() {
  return {
    type: CLOSE_MODAL,
  }
}

export function goToStep(step: number) {
  return {
    type: GO_TO_STEP,
    step,
  }
}

export function resetErrors() {
  return {
    type: RESET_ERRORS,
  }
}

export function inviteParticipant(profileId: string, email: string) {
  return async (dispatch) => {
    dispatch({
      type: INVITE_PARTICIPANT_STARTED,
    })

    try {
      await postInvite(profileId, email)
      await dispatch(
        loadGroup({
          reload: true,
        }),
      )
      dispatch(
        addFlashMessage(
          'info',
          i18n.t('components.flash_messages.invited_participant', {
            email,
          }),
          {
            glyph: 'success',
          },
        ),
      )
      dispatch({
        type: INVITE_PARTICIPANT_COMPLETED,
      })
    } catch (error) {
      const errorCode = getErrorCode(error)

      if (errorCode === 'UNKNOWN_ERROR') {
        reportGroupedError('inviteParticipant', error, {
          inviteeProfileId: profileId,
          inviteeEmail: email,
        })
      }

      dispatch({
        type: INVITE_PARTICIPANT_FAILED,
        errorCode,
      })
    }
  }
}

function getErrorCode(error: Error | HTTPError): InviteErrorCode {
  if (
    // @ts-expect-error TS2339
    error.context &&
    // @ts-expect-error TS2339
    typeof error.context.text === 'string' &&
    // @ts-expect-error TS2339
    error.context.text.includes('Email address is already in use')
  ) {
    return 'ALREADY_REGISTERED'
  } else {
    return 'UNKNOWN_ERROR'
  }
}
